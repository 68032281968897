<template>
  <div class="body container">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link
            :to="{ name: 'ProfileForAdmin', params: { id: userId } }"
          >
            {{ parentName }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <router-link :to="{ name: 'Balance', query: { username } }">
            {{ $t("pageTitle.Balance") }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ pageTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <h1 class="text-center mt-5 mb-3">
      <span>{{ pageTitle }}</span>
    </h1>
    <h2>{{ $t("balance.detail.orderItems") }}</h2>
    <el-table
      style="width: 100%"
      :data="[this.balanceTransactionDetail]"
      :summary-method="getSummaries"
      show-summary
    >
      <el-table-column :label="$t('column.serviceName')" property="title">
        {{ $t("balance.detail.depositService") }}
      </el-table-column>
      <el-table-column :label="$t('column.price')">
        {{ transactionCost }}
      </el-table-column>
    </el-table>
    <hr class="separate-line" />
    <h2 class="text-center">{{ $t("balance.detail.payment") }}</h2>
    <h4>{{ $t("balance.detail.paymentType") }}</h4>
    <el-table
      v-if="balanceTransactionDetail.payment_info"
      :data="[balanceTransactionDetail.payment_info]"
    >
      <el-table-column
        :label="$t('column.paymentType')"
        prop="title"
        width="240px"
      />
      <el-table-column :label="$t('column.details')">
        <template v-if="scope.row.account_name !== ''" slot-scope="scope">
          <div class="paymentInfo">
            <div v-if="scope.row.account_name">
              {{ $t("bill.accountName") }}{{ scope.row.account_name }}
            </div>
            <div v-if="scope.row.bank">
              {{ $t("bill.bank") }}{{ scope.row.bank }}
            </div>
            <div v-if="scope.row.account_number">
              <span v-if="isMobilePayment">{{ $t("bill.account") }}</span>
              <span v-else>{{ $t("bill.accountNumber") }}</span
              >{{ scope.row.account_number }}
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-row>
      <h4>{{ $t("transaction.transactions") }}</h4>
      <el-table
        style="width: 100%"
        :data="[formatteKeyToCamelCase(balanceTransactionDetail.transaction)]"
      >
        <el-table-column :label="$t('column.date')" property="displayDate" />
        <el-table-column :label="$t('column.amount')">
          <template slot-scope="scope">
            {{
              addCurrencySymbol(
                scope.row.originalPrice,
                scope.row.originalCurrency
              )
            }}
          </template>
        </el-table-column>
        <el-table-column :label="`Account Last 5 Digits`">
          <template slot-scope="scope">
            {{ scope.row.account_last_five || "-" }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('transaction.form.file')">
          <template slot-scope="scope">
            <el-image
              v-if="scope.row.file"
              style="width: 50px; height: 50px"
              :src="scope.row.file"
              :preview-src-list="[scope.row.file]"
            >
            </el-image>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('column.remark')">
          <template slot-scope="scope">
            {{ scope.row.remark || '-' }}
          </template>
        </el-table-column>
        <el-table-column :label="`Editor`">
          <template slot-scope="scope">
            <span v-if="scope.row.remark_by_user">
              {{
                userMethods.displayName(
                  scope.row.remark_by_user.first_name,
                  scope.row.remark_by_user.last_name
                )
              }}
            </span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('balance.detail.viewDepositHistory')">
          <template>
            <router-link :to="{ name: 'Balance', query: { username } }">
              {{ $t("balance.detail.viewDepositHistory") }}
            </router-link>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import moneyMixin from "@/mixins/money";
import profileApi from "@/apis/profile";
import balanceApi from "@/apis/balance";
import { instant, user } from "@ivy-way/material";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },
  components: { Breadcrumb },
  mixins: [moneyMixin],
  computed: {
    pageTitle() {
      return this.$t("pageTitle.balanceTransactionDetail");
    },
    instant() {
      return instant;
    },
    userMethods() {
      return user;
    },
    parentName() {
      if (!this.basicInfo) return "";
      return `${this.basicInfo.first_name} ${this.basicInfo.last_name}`;
    },
    userId() {
      if (!this.basicInfo) return "";
      return this.basicInfo.id;
    },
    username() {
      if (!this.basicInfo) return "";
      return this.basicInfo.username;
    },
    transactionCost() {
      return this.addCurrencySymbol(
        Number(this.balanceTransactionDetail.transaction.original_price),
        this.balanceTransactionDetail.transaction.original_currency
      );
    }
  },
  data() {
    return {
      basicInfo: {},
      balanceTransactionDetail: {}
    };
  },
  async created() {
    const { basic_info: basicInfo } = await profileApi.getUserData(
      this.$route.params.parentUserId
    );
    this.basicInfo = basicInfo;
    this.initialhBalanceTransaction();
  },
  methods: {
    async initialhBalanceTransaction() {
      const { account_balance_record } = await balanceApi.getBalanceTransaction(
        this.$route.params.balanceTransactionId
      );
      this.balanceTransactionDetail = account_balance_record;
    },
    formatteKeyToCamelCase() {
      const transaction = this.balanceTransactionDetail.transaction;
      if (!transaction) return {};
      return {
        ...transaction,
        displayDate: this.instant.formatDate(transaction.datetime),
        originalPrice: Number(transaction.original_price),
        originalCurrency: transaction.original_currency,
        editor: transaction.editor || "-",
        account_last_five: transaction.account_last_five || ""
      };
    },
    getSummaries(param) {
      const { columns, data: orderItems } = param;
      const sums = [];
      columns.forEach((column, index) => {
        let columnsText = "";
        if (index === 0) columnsText = "Total";
        if (index === 1) columnsText = this.transactionCost;
        sums[index] = columnsText;
      });
      return sums;
    }
  }
};
</script>

<style scoped>
.paymentInfo {
  margin: 4px;
  padding: 9.5px;
  font-size: 14px;
  color: #333;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}
</style>
